import {AnnouncementTO} from 'api/entities';

export enum AnnouncementStatus {
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
  PAST = 'PAST'
}

export function getAnnouncementStatus(announcement: AnnouncementTO): AnnouncementStatus {
  const now = Date.now();
  if (announcement.visibleFrom < now && now < announcement.visibleUntil) {
    return AnnouncementStatus.ACTIVE;
  } else if (now < announcement.visibleUntil) {
    return AnnouncementStatus.UPCOMING;
  }
  return AnnouncementStatus.PAST;
}
