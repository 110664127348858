import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AnnouncementTO} from 'api/entities';
import {Observable} from 'rxjs';
import {apiVersion} from '../../../api-version.constant';
import {remove} from '../../services/array.service';
import {getCacheHeaders} from '../shared.service';

@Injectable({providedIn: 'root'})
export class AnnouncementsCacheHelper {
  constructor(private http: HttpClient) {}

  createAnnouncement(announcement: AnnouncementTO): void {
    this.getAnnouncements().subscribe(announcements => announcements.push(announcement));
  }

  updateAnnouncement(announcement: AnnouncementTO): void {
    this.getAnnouncements().subscribe(announcements =>
      Object.assign(
        announcements.find(a => a.id === announcement.id),
        announcement
      )
    );
  }

  deleteAnnouncement(id: number): void {
    this.getAnnouncements().subscribe(announcements => remove(announcements, {id}));
  }

  private getAnnouncements(): Observable<AnnouncementTO[]> {
    return this.http.get<AnnouncementTO[]>(`${apiVersion}announcements`, getCacheHeaders(true));
  }
}
