import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AnnouncementTO} from 'api/entities';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {apiVersion} from '../../../api-version.constant';
import {getCacheHeaders} from '../shared.service';
import {AnnouncementsCacheHelper} from './announcements.cache-helper';

@Injectable({providedIn: 'root'})
export class AnnouncementsEntity {
  constructor(
    private http: HttpClient,
    private announcementsCacheHelper: AnnouncementsCacheHelper
  ) {}

  getAnnouncements(getCache?: boolean): Observable<AnnouncementTO[]> {
    return this.http.get<AnnouncementTO[]>(`${apiVersion}announcements`, getCacheHeaders(getCache));
  }

  createAnnouncement(announcement: AnnouncementTO): Observable<AnnouncementTO> {
    return this.http
      .post<AnnouncementTO>(`${apiVersion}announcements`, announcement)
      .pipe(tap(a => this.announcementsCacheHelper.createAnnouncement(a)));
  }

  updateAnnouncement(announcement: AnnouncementTO): Observable<AnnouncementTO> {
    return this.http
      .put<AnnouncementTO>(`${apiVersion}announcements/${announcement.id}`, announcement)
      .pipe(tap(a => this.announcementsCacheHelper.updateAnnouncement(a)));
  }

  deleteAnnouncement(id: number): Observable<void> {
    return this.http.delete<void>(`${apiVersion}announcements/${id}`).pipe(tap(() => this.announcementsCacheHelper.deleteAnnouncement(id)));
  }
}
